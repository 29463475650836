import { ref, computed, reactive } from 'vue'
import { defineStore } from 'pinia'
import { AUTH_PHRASE } from '@/constants'
import { useRouter } from 'vue-router'
import { $on, $off, Events, account, signMessage, connect, disconnect } from '@kolirt/vue-web3-auth'
import NoSleep from 'nosleep.js'

import { useApiStore } from './api'

export const useUserStore = defineStore('user', () => {
  const router = useRouter()
  const nosleep = new NoSleep()

  const user = ref()
  const walletLoading = ref(true)

  const address = computed(() => account.address)
  const shortAddress = computed(() => account.shortAddress)
  const isConnected = computed(() => account.connected || false)
  const accessToken = computed(() => useApiStore().accessToken)
  const refreshToken = computed(() => useApiStore().refreshToken)
  const tokenSubject = computed(() => useApiStore().tokenSubject)
  const tokenValid = computed(() => useApiStore().tokenValid(refreshToken.value))
  const isAuthenticated = computed(() => tokenValid.value && tokenSubject.value == address.value)
  const hasUser = computed(() => !!user.value)
  const canShowConnect = computed(() => !walletLoading.value && !hasUser.value)

  const loading = reactive({
    connecting: false,
    authenticating: false,
    loadingUser: false,
  })

  $on(Events.Connected, () => {
    if (isConnected.value) {
      if (!isAuthenticated.value) {
        authenticate()
      } else {
        loadUser()
      }
    }
  })

  $on(Events.Disconnected, async () => {
    await useApiStore().clearToken()
    user.value = null
    if (router.currentRoute.value.meta.requiresAuth) {
      router.push({ name: 'home' })
    }
  })

  const handler = (state) => {
    nosleep.disable()
    if (state) {
      nosleep.enable()
    }
    loading.connecting = !state
    $off(Events.ModalStateChanged, handler)
  }

  $on(Events.ModalStateChanged, handler)

  const authenticate = async () => {
    loading.authenticating = true
    try {
      const signature = await signMessage(AUTH_PHRASE)
      await useApiStore().auth(address.value, signature)
    } catch (error) {
      console.error('Failed to authenticate', error)
      throw error
    } finally {
      loading.authenticating = false
    }
    await loadUser()
  }

  const loadUser = async () => {
    if (!address.value) {
      return
    }
    loading.loadingUser = true
    try {
      user.value = await useApiStore().getUser()
    } catch (error) {
      console.error('Failed to load user', error)
      throw error
    } finally {
      loading.loadingUser = false
    }
  }

  setTimeout(() => {
    walletLoading.value = false
  }, 1000)

  return {
    user,
    isConnected,
    isAuthenticated,
    connect,
    disconnect,
    address,
    shortAddress,
    walletLoading,
    canShowConnect,
    hasUser,
  }
})
