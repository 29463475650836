<script setup>
import { ref, onMounted, watch } from 'vue'
import ChatComp from './ChatComp.vue'

const isOpen = ref(false)
const shouldTeleport = ref(false)

const checkContainer = () => {
  const container = document.getElementById('chat-container')
  console.log('Checking for container:', !!container) // Debug log
  shouldTeleport.value = !!container
}

onMounted(() => {
  checkContainer()
})

// Watch isOpen to recheck for container when chat is opened
watch(isOpen, (newValue) => {
  if (newValue) {
    checkContainer()
  }
})
</script>

<template>
  <div class="floating-chat">
    <div class="is-flex is-flex-direction-column is-justify-content-end">
      <div class="chat-comp-container is-flex-grow-1 mb-2" v-show="isOpen">
        <chat-comp :should-focus="true" />
      </div>

      <div class="is-flex is-justify-content-right is-align-items-center">
        <button class="button" @click="isOpen = !isOpen" style="pointer-events: auto">
          <span class="icon">
            <message-icon v-if="!isOpen" />
            <message-off-icon v-else />
          </span>
        </button>
      </div>
    </div>
  </div>
</template>

<style scoped>
.floating-chat {
  position: fixed;
  justify-content: end;
  bottom: 2rem;
  right: 1rem;
  z-index: 1000;
  width: 30rem;
  max-height: 30rem;
  pointer-events: none;
  max-height: calc(100vh - 6rem);
}

.chat-comp-container {
  height: 100%;
}

@media (max-width: 640px) {
  .floating-chat {
    bottom: 5.5rem;
    width: calc(100vw - 2rem);
  }
}

@media (max-height: 524px) {
  .floating-chat {
    max-height: calc(100vh - 6rem);
  }
}
</style>
