import { ref, computed } from 'vue'
import { defineStore } from 'pinia'
import { SERVER_URL } from '@/constants'
import { jwtDecode } from 'jwt-decode'
import { toast } from 'vue3-toastify'

export const useApiStore = defineStore(
  'api',
  () => {
    const token = ref(null)

    const accessToken = computed(() => token.value?.accessToken)
    const refreshToken = computed(() => token.value?.refreshToken)
    const tokenType = computed(() => token.value?.tokenType)
    const tokenSubject = computed(() => {
      if (!accessToken.value) {
        return null
      }
      const decodedToken = jwtDecode(accessToken.value)
      return decodedToken.sub
    })
    const tokenExpiry = computed(() => {
      if (!accessToken.value) {
        return null
      }
      const decodedToken = jwtDecode(accessToken.value)
      return decodedToken.exp
    })

    const tokenValid = (token) => {
      if (!token) {
        return false
      }
      const decodedToken = jwtDecode(token)
      return decodedToken.exp > Date.now() / 1000
    }

    const _fetch = async (url, options = {}) => {
      await refreshTokenIfNeeded()
      const headers = { ...options.headers }
      if (accessToken.value) {
        headers.Authorization = `${tokenType.value} ${accessToken.value}`
      }
      const fetchOptions = { ...options, headers }
      const response = await fetch(`${SERVER_URL}${url}${options.query || ''}`, fetchOptions)
      if (!response.ok) {
        console.error('fetch error', response)
        toast.error(`Failed to fetch data: ${url}`)
        return null
      }
      return await response.json()
    }

    const auth = async (address, signature) => {
      const data = await _fetch('/auth/token', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ userId: address, signature }),
      })
      if (data.accessToken) {
        token.value = data
      }
      return getUser()
    }

    const clearToken = () => (token.value = null)

    const refreshTokenIfNeeded = async () => {
      // check if the token is expired
      if (!accessToken.value) {
        return
      }
      if (tokenValid(accessToken.value)) {
        return
      }
      if (tokenValid(refreshToken.value)) {
        const formData = new FormData()
        formData.append('token', refreshToken.value)
        try {
          const response = await fetch(`${SERVER_URL}/auth/refresh`, {
            method: 'POST',
            body: formData,
          })
          const data = await response.json()
          if (data.accessToken) {
            token.value = data
          }
        } catch (error) {
          console.error('Failed to refresh token', error)
          clearToken()
        }
      } else {
        clearToken()
      }
    }

    const getUser = async (address) => {
      if (!address) {
        return await _fetch(`/user/me`)
      }
      return await _fetch(`/user/${address}`)
    }

    const getApps = async () => await _fetch('/app')

    const getJob = async (jobId) =>
      await _fetch(`/job/${jobId}`, {
        method: 'GET',
        headers: {
          Authorization: `${tokenType.value} ${accessToken.value}`,
        },
      })

    const getJobs = async (offset = 0, limit = 25, appId = null) =>
      await _fetch('/job/finished', {
        query: `?offset=${offset}&limit=${limit}${appId ? `&app_id=${appId}` : ''}`,
      })

    const getUserJobs = async (offset = 0, limit = 25) =>
      await _fetch('/job', {
        query: `?offset=${offset}&limit=${limit}`,
      })

    const getUserFavorites = async () => await _fetch('/user/me/favorite')

    const submitJob = async (formData) =>
      await _fetch('/job', {
        method: 'POST',
        headers: {
          Authorization: `${tokenType.value} ${accessToken.value}`,
        },
        body: formData,
      })

    const getPerfStats = async () => {
      const response = await fetch('/api/perf-stats')
      return await response.json()
    }

    const getDevlog = async () => await _fetch('/devlog')

    const submitFeedback = async (formData) =>
      await _fetch('/bugreport', {
        method: 'POST',
        body: formData,
      })

    const addFavorite = async (jobId) =>
      await _fetch(`/job/${jobId}/favorite`, {
        method: 'PUT',
      })

    const removeFavorite = async (jobId) =>
      await _fetch(`/job/${jobId}/favorite`, {
        method: 'DELETE',
      })

    const getPrices = async () => await _fetch('/prices')

    const updateUser = async (formData) =>
      await _fetch('/user/me', {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      })

    const sendMessage = async (message, threadId) => {
      const body = { message }
      if (threadId) {
        body.thread_id = threadId
      }
      return await _fetch('/chat', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(body),
      })
    }

    return {
      token,
      accessToken,
      refreshToken,
      tokenSubject,
      tokenExpiry,
      tokenValid,
      fetch: _fetch,
      auth,
      clearToken,
      getApps,
      getDevlog,
      getJob,
      getJobs,
      getPerfStats,
      getUser,
      getUserJobs,
      getUserFavorites,
      submitFeedback,
      submitJob,
      addFavorite,
      removeFavorite,
      getPrices,
      sendMessage,
      updateUser,
    }
  },
  {
    persist: true,
  },
)
