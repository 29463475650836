<script setup>
import { ref, onMounted, watch } from 'vue'
import { RouterView, useRoute } from 'vue-router'
import { storeToRefs } from 'pinia'
import { useUserStore } from './stores/user'
import SidebarComp from './components/layout/SidebarComp.vue'
import MobileBarComp from './components/layout/MobileBarComp.vue'
import FooterComp from './components/layout/FooterComp.vue'
import GlitchText from './components/GlitchText.vue'
import FloatingChatComp from './components/chat/FloatingChatComp.vue'

const route = useRoute()
const userStore = useUserStore()

const { shortAddress, isConnected, isAuthenticated } = storeToRefs(userStore)

const isMobile = ref(false)

onMounted(async () => {
  isMobile.value = window.innerWidth < 640
  window.addEventListener('resize', () => {
    isMobile.value = window.innerWidth < 640
  })
})

watch(route, (newRoute, oldRoute) => {
  if (newRoute.name !== oldRoute.name) {
    window.scrollTo(0, 0)
  }
})
</script>

<template>
  <sidebar-comp v-if="!isMobile" />

  <main
    ref="mainContent"
    class="main-content is-flex is-flex-direction-column is-flex-grow-1"
    :class="{ 'main-content-desktop': !isMobile }"
  >
    <div
      class="main-content-container container px-2 py-0 is-fullhd is-relative is-flex is-flex-direction-column"
    >
      <div class="content is-flex is-flex-direction-column is-flex-grow-1">
        <div
          v-if="isConnected"
          class="connection-status is-size-7 is-flex is-justify-content-end has-background-primary-00-blurred"
        >
          <span class="is-square-pixel-7 mr-2">Connected as</span>
          <span>{{ shortAddress }}</span>
          <a class="is-square-pixel-7 ml-2 has-text-info" @click="userStore.disconnect">
            Disconnect
          </a>
        </div>

        <h1
          v-if="route.meta.name && route.meta.showName"
          class="is-title is-square-pixel-7 my-2 is-flex-shrink-1"
        >
          <glitch-text :min-interval="1" :max-interval="3">
            {{ route.meta.name }}
          </glitch-text>
        </h1>

        <router-view />
      </div>
    </div>

    <floating-chat-comp v-if="route.name !== 'chat' && isAuthenticated" />

    <footer-comp />
  </main>

  <mobile-bar-comp v-if="isMobile" />
</template>

<style scoped>
.main-content-container {
  min-height: 100vh;
}
</style>
