import { ref, computed } from 'vue'
import { defineStore } from 'pinia'
import { useApiStore } from './api'
import { marked } from 'marked'
import { toast } from 'vue3-toastify'

export const useChatStore = defineStore('chat', () => {
  const newMessage = ref('')
  const pendingMessage = ref(null)
  const messages = ref([])
  const threadId = ref(null)
  const isLoading = ref(false)
  const _mood = ref(null)

  const mood = computed({
    get: () => (isLoading.value ? 'neutral' : _mood.value),
    set: (value) => (_mood.value = value),
  })

  const allMessages = computed(() => [
    ...messages.value,
    ...(pendingMessage.value ? [{ content: pendingMessage.value, role: 'user' }] : []),
  ])

  const sendMessage = async () => {
    if (isLoading.value) {
      return
    }
    if (!newMessage.value.trim()) {
      return
    }
    const api = useApiStore()
    try {
      isLoading.value = true
      pendingMessage.value = newMessage.value
      newMessage.value = ''
      const response = await api.sendMessage(pendingMessage.value, threadId.value)
      parseResponse(response)
    } catch (error) {
      toast.error('Failed to send message')
      newMessage.value = pendingMessage.value
      pendingMessage.value = null
    } finally {
      isLoading.value = false
    }
  }

  const parseResponse = (response) => {
    messages.value.push({
      content: pendingMessage.value,
      role: 'user',
    })

    // MOOD INSTRUCTIONS:
    // • Append `[[mood: <mood_name>]]` at the end of your responses.
    // • Choose the appropriate `<mood_name>` based on the user's input. Available moods: angry, blink, confident, eyebrowraise, happy, neutral, surprise.
    // • Ensure that the `<mood_name>` corresponds to the content of your response.
    // • Do not include the `[[mood: <mood_name>]]` text in the visible part of the message to the user.
    // • Be really emotive, you should use the mood tags a lot based on the user's input, and guess your own mood.

    // Extract mood from response
    const moodRegex = /\[\[mood:([a-z]+)\]\]/i
    const moodMatch = response.message.match(moodRegex)
    if (moodMatch) {
      const rawmood = moodMatch[1]
      if (
        ['angry', 'blink', 'confident', 'eyebrowraise', 'happy', 'neutral', 'surprise'].includes(
          rawmood,
        )
      ) {
        mood.value = rawmood
      }
      response.message = response.message.replace(moodRegex, '')
    }

    messages.value.push({
      content: response.message.trim(),
      role: 'assistant',
    })
    threadId.value = response.threadId
    pendingMessage.value = null
  }

  const parseMessage = (message) => {
    return marked(message)
  }

  return {
    sendMessage,
    allMessages,
    newMessage,
    pendingMessage,
    messages,
    threadId,
    isLoading,
    mood,
    parseMessage,
  }
})
