import { createRouter, createWebHistory } from 'vue-router'
import { storeToRefs } from 'pinia'
import { useUserStore } from '@/stores/user'

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: [
    {
      path: '/',
      name: 'home',
      component: () => import('@/views/HomeView.vue'),
    },
    {
      path: '/feed',
      name: 'feed',
      component: () => import('@/views/FeedView.vue'),
      meta: {
        sidebar: true,
        icon: 'EarthIcon',
        name: 'Feed',
        showName: false,
      },
    },
    {
      path: '/apps',
      name: 'apps',
      component: () => import('@/views/AppsView.vue'),
      meta: {
        sidebar: true,
        icon: 'AppsIcon',
        name: 'Apps',
        showName: true,
      },
    },
    {
      path: '/nodes',
      name: 'nodes',
      component: () => import('@/views/NodesView.vue'),
      meta: {
        sidebar: true,
        icon: 'ExpansionCardIcon',
        name: 'Nodes',
        showName: true,
      },
    },
    {
      path: '/chat',
      name: 'chat',
      component: () => import('@/views/ChatView.vue'),
      meta: {
        sidebar: true,
        icon: 'MessageIcon',
        name: 'Ask Infra',
        requiresAuth: true,
        inMenu: true,
        showName: true,
      },
    },
    {
      path: '/devlog',
      name: 'devlog',
      component: () => import('@/views/DevlogView.vue'),
      meta: {
        sidebar: true,
        icon: 'PostIcon',
        name: 'Devlog',
        inMenu: true,
        showName: true,
      },
    },
    {
      path: '/feedback',
      name: 'feedback',
      component: () => import('@/views/FeedbackView.vue'),
      meta: {
        sidebar: true,
        icon: 'ShieldBug',
        name: 'Feedback',
        inMenu: true,
        showName: true,
      },
    },
    {
      path: '/staking',
      name: 'staking',
      component: () => import('@/views/StakingView.vue'),
      meta: {
        sidebar: true,
        icon: 'CurrencySignIcon',
        name: 'Staking',
        requiresAuth: true,
        inMenu: true,
        showName: true,
      },
    },
    {
      path: '/profile',
      name: 'profile',
      component: () => import('@/views/ProfileView.vue'),
      meta: {
        sidebar: true,
        icon: 'AccountCircleIcon',
        name: 'Profile',
        requiresAuth: true,
        showName: true,
      },
    },

    // catch all, redirect home
    {
      path: '/:pathMatch(.*)*',
      name: 'not-found',
      redirect: '/',
    },
  ],
})

// Add auth guard for protected routes
router.beforeEach((to, from, next) => {
  const userStore = useUserStore()
  const { isAuthenticated, walletLoading } = storeToRefs(userStore)
  // Check if route requires auth
  if (to.meta.requiresAuth && !walletLoading.value && !isAuthenticated.value) {
    next({ name: 'home' })
    return
  }
  next()
})

export default router
