<script setup>
import { ref, nextTick, onMounted, computed } from 'vue'
import { useChatStore } from '@/stores/chat'
import { storeToRefs } from 'pinia'

const faces = Object.fromEntries(
  ['angry', 'blink', 'confident', 'eyebrowraise', 'happy', 'neutral', 'surprise'].map((face) => [
    face,
    new URL(`../../assets/images/faces/${face}.png`, import.meta.url).href,
  ]),
)

const props = defineProps({
  shouldFocus: {
    type: Boolean,
    default: false,
  },
})

const chat = useChatStore()
const { newMessage, pendingMessage, allMessages, isLoading, mood } = storeToRefs(chat)
const { sendMessage, parseMessage } = chat
const messageInput = ref(null)
const messagesPanel = ref(null)

const currentFace = computed(() => {
  if (mood.value) {
    return faces[mood.value]
  }
  return null
})

const focusInput = async () => {
  if (props.shouldFocus) {
    await nextTick()
    if (messageInput.value) {
      messageInput.value.focus()
    }
  }
}

const scrollToBottom = () => {
  setTimeout(() => {
    if (messagesPanel.value) {
      messagesPanel.value.scrollTo({
        top: messagesPanel.value.scrollHeight,
        behavior: 'smooth',
        block: 'end',
      })
    }
  }, 100)
}

const doSendMessage = async () => {
  scrollToBottom()
  await sendMessage()
  scrollToBottom()
}

onMounted(async () => {
  await focusInput()
  scrollToBottom()
})
</script>

<template>
  <div
    class="chat-card card h-100 p-2 is-flex is-flex-direction-column"
    style="pointer-events: auto"
  >
    <div ref="messagesPanel" class="messages-panel is-flex-grow-1">
      <div>
        <div v-for="(message, index) in allMessages" :key="index" class="message mb-3">
          <div
            class="message-content is-flex is-flex-direction-column"
            :class="`is-${message.role}`"
          >
            <div
              class="box content p-2 mb-2 is-shadowless is-inline-block max-width-75"
              :class="`is-${message.role}`"
              v-html="parseMessage(message.content)"
            ></div>

            <span class="has-text-white is-size-7">
              {{ message.role === 'user' ? 'You' : 'Infra' }}
            </span>
          </div>
        </div>

        <div v-if="pendingMessage" class="message mb-3">
          <div class="message-content is-flex is-flex-direction-column is-align-items-start">
            <div
              class="box p-3 mb-2 is-shadowless has-background-black is-inline-block max-width-75"
              style="
                border: 1px solid #333;
                border-radius: 1rem;
                border-bottom-left-radius: 0;
                max-width: 90%;
              "
            >
              <div class="typing-indicator">
                <span></span>
                <span></span>
                <span></span>
              </div>
            </div>
            <span class="has-text-white is-size-7">Infra</span>
          </div>
        </div>

        <div v-if="mood" class="face is-flex is-justify-content-center">
          <img v-if="currentFace" :src="currentFace" alt="Mood" :class="{ floating: isLoading }" />
        </div>
      </div>
    </div>

    <form @submit.prevent="doSendMessage">
      <div class="field has-addons p-0 pt-2">
        <div class="control is-expanded">
          <input
            ref="messageInput"
            class="input"
            type="text"
            placeholder="Type your message..."
            v-model="newMessage"
            maxlength="128"
            :disabled="isLoading"
          />
        </div>
        <div class="control">
          <button
            :class="['button', isLoading ? 'is-loading' : 'is-primary', 'is-fullwidth']"
            type="submit"
            :disabled="isLoading"
          >
            Send
          </button>
        </div>
      </div>
    </form>
  </div>
</template>

<style scoped>
.chat-card {
  min-height: 20rem;
}

.h-100 {
  height: 100%;
}

.is-loading {
  cursor: not-allowed;
}

.messages-panel {
  overflow: auto;

  min-height: 0;
  overscroll-behavior: none;

  .message-content {
    &.is-user {
      align-items: flex-end;
    }

    &.is-assistant {
      align-items: flex-start;
    }

    .content {
      border-radius: 0.5rem;
      position: relative;

      &.is-user {
        color: var(--bulma-primary-invert);
        background: var(--bulma-primary);
        border: 1px solid #000000;
        border-bottom-right-radius: 0;
      }

      &.is-assistant {
        border: 1px solid #333;
        border-bottom-left-radius: 0;
      }
    }
  }
}

.face {
  position: relative;
  margin-top: -3rem;
}

/* Custom scrollbar styles */
::-webkit-scrollbar {
  width: 0.5rem;
}

::-webkit-scrollbar-track {
  background: transparent;
  border-radius: 0.25rem;
}

::-webkit-scrollbar-thumb {
  background: #ffffff;
  border-radius: 0.25rem;
}

::-webkit-scrollbar-thumb:hover {
  background: #cccccc;
}

/* Firefox scrollbar */
* {
  scrollbar-width: thin;
  scrollbar-color: #ffffff transparent;
}

.typing-indicator {
  display: flex;
  gap: 0.25rem;
  padding: 0.25rem 0.5rem;

  & span {
    width: 0.5rem;
    height: 0.5rem;
    background-color: #ffffff;
    border-radius: 50%;
    animation: bounce 1.4s infinite ease-in-out;

    &:nth-child(1) {
      animation-delay: -0.32s;
    }

    &:nth-child(2) {
      animation-delay: -0.16s;
    }
  }
}

.floating {
  animation: floating 1.4s infinite ease-in-out;
}

@keyframes bounce {
  0%,
  80%,
  100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-0.5rem);
  }
}

@keyframes floating {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-0.25rem);
  }
}
</style>
